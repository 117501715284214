import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jamesabaja/MedGrocer/bm-pap/src/components/Layout/Layout.js";
import Container from "layout/Container";
import Collapsible from "elements/Collapsible";
import SEO from "layout/SEO";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container isCentered mdxType="Container">
      <SEO title="Privacy Policy" mdxType="SEO" />
      <h2>{`Privacy Policy`}</h2>
      <hr />
      <div className="content is-small">
        <p>{`User should carefully read the entire following privacy statement before creating an account and/or availing MedGrocer’s products. This agreement contains important terms that affect User’s legal rights. By registering to MedGrocer’s site, User hereby agrees to the terms of this privacy statement policy.`}</p>
        <p>{`MedGrocer respects the privacy of its Users and complies with Republic Act No. 10173 also known as the Data Privacy Act of 2012, its implementing rules and regulations, and other issuances of the National Privacy Commission. MedGrocer recognizes the importance of protecting information collected from Users and has adopted this privacy policy to inform Users about how MedGrocer collects, uses, stores, transfers, discloses, and disposes information derived from their use of MedGrocer products, services, and online sites.`}</p>
        <p>{`This privacy statement is current as of its last revision date. However, this privacy statement may be amended from time to time to reflect changes and additions to the privacy policy. Please check back for the most current version before relying on any of the provisions in this privacy statement.`}</p>
        <Collapsible title="Personally Identifiable Information" mdxType="Collapsible">
          <p>{`Personally identifiable information' is information that can be used to uniquely identify a User such as name, password, age, civil status, gender, mailing address, email address, telephone number, mobile number, and other personal details. User may be asked to provide personally identifiable information during the ordering process to allow User to use the Website and for MedGrocer to respond to User’s queries and requests. MedGrocer may also use the provided personal information to inform User of special product offers, to process orders, to provide products and services, or to conduct surveys. While MedGrocer collects personally identifiable information on a voluntary basis, for certain products and services, MedGrocer's collection of personally identifiable information may be a requirement for access to its products or services.`}</p>
        </Collapsible>
        <Collapsible title="Collection of Information" mdxType="Collapsible">
          <p>{`By using MedGrocer's Website, products, and services, Users agree that MedGrocer collects and processes the personally identifiable information (as defined above) they have provided by creating a MedGrocer account, during any exchange with MedGrocer in connection with the use of their MedGrocer account. MedGrocer will not share any personally identifiable information with other parties except as described in this policy. MedGrocer also processes anonymous data, aggregated or not, in order to analyze and produce statistics related to the product usage patterns and demographics of Users. Such anonymous data does not allow the identification of Users to which it relates. MedGrocer may share anonymous data, aggregated or not, with third parties.`}</p>
        </Collapsible>
        <Collapsible title="Use of Personally Identifiable Information" mdxType="Collapsible">
          <p>{`The Personal Information MedGrocer collects from User will be used or shared with third parties for some or all of the following purposes:`}</p>
          <ul>
            <li parentName="ul">{`To facilitate the the use of services and/or access to the Website,`}</li>
            <li parentName="ul">{`To process orders,`}</li>
            <li parentName="ul">{`To deliver products purchased,`}</li>
            <li parentName="ul">{`To update User on the status of the order,`}</li>
            <li parentName="ul">{`To compare information and to verify if such information is accurate,`}</li>
            <li parentName="ul">{`To administer an account with MedGrocer, audit Users’ demographics and behavior, provide information MedGrocer deems might be useful for User or information User requested (including information from third parties),`}</li>
            <li parentName="ul">{`To send marketing and/or promotional materials about MedGrocer’s or third party’s products and services from time to time and to send newsletters from MedGrocer or other related companies,`}</li>
            <li parentName="ul">{`To provide medical consultation, prescribe medication and other treatments, give medicine assistance, and obtain and/or update medical history and records covering hospitalization, consultation, treatment, prescriptions, medicine purchases, results of laboratory tests, medical and psychological examination,`}</li>
            <li parentName="ul">{`To analyze medicine adherence, disease condition, and overall health of Users,`}</li>
            <li parentName="ul">{`To reporting adverse events from medications, medication errors, and product quality complaints to the Food and Drug Administration,`}</li>
            <li parentName="ul">{`And in exceptional circumstances, to disclose Personal Information when there are grounds to believe that the disclosure is necessary to prevent a threat to life/health, for law enforcement purposes, or for fulfillment of legal and regulatory requirements or requests.`}</li>
          </ul>
          <p>{`If, for any reason, User’s Personal Information is required to fulfill a different purpose, MedGrocer will notify User and ask for consent.`}</p>
          <p>{`Information that could identify a User personally will not be collected upon visiting the Website unless the User chooses to provide information voluntarily. Users are welcome to browse the Website at any time anonymously and privately without revealing any Personal Information.`}</p>
          <p>{`When User orders from MedGrocer, MedGrocer collects a User's personally identifiable information. MedGrocer has no obligation to keep personally identifiable information that a User makes available to other Users through a platform other than MedGrocer Website, such as in chat or other public functions.`}</p>
          <p>{`External websites and companies with links to and from MedGrocer's Website and products may collect personally identifiable information about Users when Users visit their websites. MedGrocer's privacy policy does not extend to external websites and companies or third party publishers with links to or from MedGrocer’s Website or who collect personally identifiable information through their online sites. Please refer directly to these companies and websites regarding their privacy policies.`}</p>
          <p>{`MedGrocer may release personally identifiable information to comply with court orders or laws that require us to disclose such information. In the event of a reorganization, sale or merger, we may transfer personally identifiable information to the relevant third party with User’s consent if required by law.`}</p>
        </Collapsible>
        <Collapsible title="Cookies and Other Information on a User's Machine" mdxType="Collapsible">
          <p>{`MedGrocer’s products, services, and sites may employ cookies and other technologies such as pixel tags. Cookies are bits of electronic information that can be transferred to a User's hard drive to customize a person's use of a product or online site, keep records of a User's access to an online site or product, or store information needed by User on a regular basis. Use of cookies is typically associated with websites. For example, when User signs in to MedGrocer’s Website, the Website stores User’s log-in information, IP address, and time of login as a cookie on User’s hard drive. This cookie allows User to move from page to page without having to sign in again on each page. Similarly, if User enters information during a session, such as age verification data, this will be stored as a cookie so that User will not have to re-enter such information during that session. MedGrocer may also use third party website analytic tools that employ cookies to collect certain non-personally identifiable information concerning use of our Website such as those referring to website addresses, page views, and browser types.`}</p>
        </Collapsible>
        <Collapsible title="Opt-out" mdxType="Collapsible">
          <p>{`MedGrocer gives Users the option to receive promotional email communications from MedGrocer. Users may opt out of these communications. An unsubscribe link for discontinuing receipt of promotional email communications from MedGrocer will be provided in the emails sent.`}</p>
        </Collapsible>
        <Collapsible title="Storage and Security of Personally Identifiable Information" mdxType="Collapsible">
          <p>{`Personally identifiable information provided to MedGrocer will be collected, processed, and stored by MedGrocer in the cloud. MedGrocer has taken reasonable steps to protect the information Users share, including, but not limited to, setup of processes, equipment and software to avoid unauthorized access or disclosure of this information.`}</p>
        </Collapsible>
        <Collapsible title="Corrections, Updates, and Removal of Personally Identifiable Information" mdxType="Collapsible">
          <p>{`If User is concerned about the accuracy of personally identifiable information maintained by MedGrocer or wish to review, access, amend, or correct it, or would like User’s personally identifiable information removed from MedGrocer's records or otherwise deactivated, please contact MedGrocer at `}<a parentName="p" {...{
              "href": "mailto:dpo@medgrocer.com."
            }}>{`dpo@medgrocer.com.`}</a>{` MedGrocer may decline requests that are unreasonably repetitive, require disproportionate technical effort, jeopardize the privacy of others, or are extremely impractical.`}</p>
        </Collapsible>
        <Collapsible title="Contact MedGrocer’s Data Privacy Officer" mdxType="Collapsible">
          <p>{`MedGrocer’s Data Privacy Officer provides support with any data privacy related questions, comments, concerns, or complaints. You may contact MedGrocer’s Data Privacy Officer through the following information:`}</p>
          <p>{`MedGrocer 24th Floor, Centuria Medical Makati Building, Gen. Luna Street, corner Salamanca Street, Makati City `}<br />{`
Contact Number: (02) 8332 3768 `}<br />{`
Email address: `}<a parentName="p" {...{
              "href": "mailto:dpo@medgrocer.com"
            }}>{`dpo@medgrocer.com`}</a></p>
          <p>{`MedGrocer commits to resolve complaints about our collection or use of personal information. Users with inquiries or complaints regarding our Privacy Statement should first contact MedGrocer. If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our DPO (refer to the contact details above). MedGrocer’s Data Privacy Officer will always use reasonable efforts to address and settle any requests or complaints brought to its attention.`}</p>
        </Collapsible>
      </div>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      